<script setup lang="ts">
import type { Account } from "@/api-types";
import { useMainStore } from "@/stores/main";
import useString from "@/composables/useString";
import { computed, provide, ref, type PropType } from "vue";
import type { FormFieldState } from "@/types";

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },  
});

const mainStore = useMainStore();
const { capitalizeEachWord } = useString();

interface AccountWithLevel extends Account { level: number };

const toggle = ref<HTMLButtonElement>();

const getAncestors = (account: Account) => {
  const ancestors: Account[] = [];
  let current = account;
  while (current.parentId) {
    const parent = mainStore.accounts.find((a) => a.id === current.parentId);
    if (!parent) break;
    ancestors.push(parent);
    current = parent;
  }
  return ancestors;
};

const getLevel = (account: Account) => {
  return getAncestors(account).length;
};

const accountsWithLevel = computed(() => {
  return mainStore.accounts.map((account) => ({
    ...account,
    name: capitalizeEachWord(account.name!, " ", ["AS", "NOVA"]),
    level: getLevel(account),
  } as AccountWithLevel));
});

// not very intuitive but Select requires ut from FormField which we're not using here ... consider refactoring
provide("state", props.disabled ? "disabled" : "base" as FormFieldState);
// ---

</script>
<template>
  <Select class="w-full xl:w-80" label-field="name" value-field="id" :items="accountsWithLevel" :model-value="mainStore.account?.id" @update:model-value="(val) => mainStore.setAccount(val)">
    <template #toggle="{ item }: { item: AccountWithLevel }">
      <div class="toggle" ref="toggle">{{ item?.name || "Alle lokasjoner" }}</div>
    </template>
    <template #item="{ item, select }">
      <button class="item w-full" :class="{ '!pl-8': item.level === 1, '!pl-12': item.level === 2, '!pl-16': item.level === 3, '!pl-20': item.level === 4 }" @click.prevent="() => { select(item); toggle?.focus(); }">
        <Icon background="neutral" name="office" v-if="item.level === 0" />
        <span :class="{ 'text-blue-60': mainStore.account?.id === item.id }">{{ item.name }}</span>
      </button>
    </template>
  </Select>
</template>

<style scoped>
.toggle {
  @apply h-14 w-full flex items-center rounded-full bg-interface-40 pl-6 pr-12 whitespace-nowrap text-black bg-no-repeat;
  background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNSI+PHBhdGggZD0iTTE3LjAzMDYgMTYuNTg3N2EuNzUwOC43NTA4IDAgMCAxIC4yMi41MzA2Ljc1MDEuNzUwMSAwIDAgMS0uMjIuNTMwN2wtNC41IDQuNWEuNzQ5NC43NDk0IDAgMCAxLS41MzA2LjIxOTkuNzQ4Ni43NDg2IDAgMCAxLS4yODcyLS4wNTcyLjc0ODQuNzQ4NCAwIDAgMS0uMjQzNC0uMTYyN2wtNC41LTQuNWEuNzUwOC43NTA4IDAgMCAxIDAtMS4wNjEzLjc1MDYuNzUwNiAwIDAgMSAxLjA2MTIgMEwxMiAyMC41NThsMy45Njk0LTMuOTcwM2EuNzQ4Ni43NDg2IDAgMCAxIC41MzA2LS4yMi43NTA5Ljc1MDkgMCAwIDEgLjUzMDYuMjJabS05LTcuOTM4OEwxMiA0LjY3ODZsMy45Njk0IDMuOTcwM2EuNzUwMy43NTAzIDAgMSAwIDEuMDYxMi0xLjA2MTJsLTQuNS00LjVhLjc0OTguNzQ5OCAwIDAgMC0xLjA2MTIgMGwtNC41IDQuNWEuNzUwNC43NTA0IDAgMSAwIDEuMDYxMiAxLjA2MTJaIiBmaWxsPSIjMUIxQzFEIi8+PC9zdmc+");
  background-position: right 1rem center;
  background-size: 1.5rem;

  &:hover {
    @apply bg-interface-50;
}
}

button:has(.toggle):focus-visible {
  @apply outline-none;

  .toggle {
    @apply bg-interface-20;
  }
}

.item {
  @apply flex items-center p-4 gap-2 outline-none;

  &:focus-visible,
  &:hover {
    @apply bg-interface-20;
  }
}
</style>