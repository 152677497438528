<script setup lang="ts">
import { reactive, type PropType } from "vue";

type Tag = "h1" | "h2" | "h3" | "h4";

type Size = "huge" | "large" | "medium" | "small" | "x-small" | "xx-small";

const props = defineProps({
  tag: {
    type: String as PropType<Tag>,
    required: true,
  },
  size: {
    type: String as PropType<Size>,
    default: "medium",
  },
  branded: {
    type: Boolean,
    default: false,
  },
});

const classes = reactive({
  "text-display-huge font-medium": props.size === "huge",
  "text-display-large font-medium": props.size === "large",
  "text-display-medium font-medium": props.size === "medium",
  "text-display-small font-medium": props.size === "small",
  "text-display-x-small font-medium": props.size === "x-small",
  "text-display-xx-small font-medium": props.size === "xx-small",
  branded: props.branded,
});
</script>

<template>
  <div class="flex flex-wrap items-center justify-between gap-4">
    <component :is="tag" :class="classes">
      <slot />
    </component>
    <div v-if="$slots.right">
      <slot name="right" />
    </div>
  </div>
</template>

<style scoped>
h1, h2, h3, h4 {
  @apply text-ink-contrast;
}
.branded {
  @apply relative pr-6;

  &::before {
    @apply absolute bottom-0 right-0 h-4 w-4 rounded-full bg-green-90;
    content: "";
  }
}
</style>
