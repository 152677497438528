<script setup lang="ts">
import { useMainStore } from "@/stores/main";
import { useAccess } from "@/config/useAccess";

const mainStore = useMainStore();
const { hasAccess } = useAccess();

</script>

<template>
  <div class="p-4 pt-5 lg:p-8 lg:pt-8 min-h-screen" v-if="mainStore.initialized">
    <div class="pb-24">
      <TopBar />
      <div class="mx-auto mt-4 lg:mt-6 max-w-screen-xl">
        <div class="flex items-center justify-center gap-2">
          <NavBar />
          <LocationSelector :disabled="!hasAccess('account-change')" />
          <LanguageSelector />
        </div>
        <router-view></router-view>
      </div>
    </div>
    <Toast />
    <FeedbackForm />
  </div>
  <Footer />
</template>
